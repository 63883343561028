.box {
    height: 100%;
    border-color: var(--main-gray) !important;
}
.box hr {
    margin: 30px 0;
    border-width: 2px;
    border-color: var(--main-gray);
}
.box .card-header {
    background-color: var(--purple);
    border-color: var(--purple);
    color: white;
    font-weight: bold;
}
.box p {
    font-size: 13px;
    margin: 0;
}
.box h6 {
    font-size: 15px;
    line-height: 15px;
    margin: 0;
}
.box h5 {
    font-size: 19px;
}
.rate-light-green {
    background-color: var(--light-green);
}
.rating {
    line-height: 17px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    font-size: 17px;
}
.status {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-block;
}
.dot-yellow {
    background-color: var(--yellow);
}
.dot-gray {
    background-color: var(--gray);
}
.dot-green {
    background-color: var(--green);
}
.dot-blue {
    background-color: var(--blue);
}
.dot-red {
    background-color: var(--red);
}
.square {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -1px;
}
.blue {
    background-color: var(--light-blue);
}
.green {
    background-color: var(--light-green);
}
.red {
    background-color: var(--red);
}