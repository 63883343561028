.progressbar {
    margin: 0;
    padding: 29px 0 58px;
}
.progressbar li {
    list-style-type: none;
    float: left;
    margin: 0 -1px;
    position: relative;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}
.progressbar li::before {
    width: 12px;
    height: 12px;
    content: "";
    display: block;
    text-align: center;
    margin: 0 auto 27px auto;
    border-radius: 50%;
    background-color: #D8D8D8;
    z-index: 1;
    position: relative;
}
.progressbar li:after {
    width: 100%;
    height: 4px;
    content: '';
    position: absolute;
    background-color: #D8D8D8;
    top: 4px;
    left: 0;
    z-index: 0;
}
.progressbar li.active:before,
.progressbar li.current:before,
.progressbar li.completed:before {
    border-color: transparent;
    content: "";
    background-color: var(--green-secondary);
    border: none;
}
.progressbar li.active::after,
.progressbar li.active.current::after {
    background: var(--green-secondary);
}
.progressbar li.current::after,
.progressbar li.active.last::after {
    background: linear-gradient(to right, var(--green-secondary) 50%, #d7d7d7 50%);
}
.progressbar li.current:last-child::after {
    background: var(--green-secondary);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.progressbar li:first-child::after {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.progressbar li.active {
    text-decoration: line-through;
}
.progressbar li.active,
.progressbar li.current {
    color: var(--black);
}
.progressbar li.current {
    font-weight: bold;
}

@media (max-width: 767px) {
    .progressbar li {
        font-size: 11px;
        line-height: 13px;
    }
    .progressbar li::before {
        margin: 0 auto 10px auto;
    }
    .progressbar {
        padding: 18px 0 48px;
    }
}