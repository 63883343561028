.sidebar {
    position: fixed;
    width: 260px;
    padding: 44px 15px;
    left: 0;
    top: 0;
    bottom: 0;
}
.chart-percent {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
}
.menu-icon {
    width: 20px;
}
.sidebar-menu li {
    padding: 10px 15px;
}
.sidebar-menu li.active {
    background-color: var(--activePurple);
}
.sidebar-menu a {
    color: white;
}
.sidebar-menu a:hover {
    color: var(--orange);
    text-decoration: none;
}
.sidebar-title-holder,
.sidebar-title {
    margin-top: 45px;
}

@media (max-width: 767px) {
    .sidebar {
        position: relative;
        width: 100%;
    }
}