.custom-tabs .nav-tabs .nav-link:hover,
.custom-tabs .nav-tabs .nav-link:focus {
    border-color: transparent transparent transparent;
}
.custom-tabs .nav-tabs .nav-link.active,
.custom-tabs .nav-tabs .nav-item.show .nav-link {
    border-color: var(--purple) var(--purple) #fff;
    border-width: 2px;
}
.custom-tabs .nav-tabs {
    border-bottom: 2px solid var(--purple);
}
.custom-tabs .nav-tabs .nav-item {
    margin-bottom: -2px;
}
.custom-tabs .nav-tabs .nav-item {
    color: var(--orange);
}
.custom-tabs .nav-tabs .nav-link.active {
    color: var(--purple);
}
