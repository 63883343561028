:root {
    --black: #000;
    --main-gray: #7c7c7c;
    --orange: #ff7001;
    --purple: #5026b5;
    --activePurple: #613bbc;
    --red: #DA4141;
    --yellow: #F7B500;
    --green: #96D231;
    --green-secondary: #79d70f;
    --gray: #929292;
    --blue: #32C5FF;
    --light-green: #9fc46e;
    --light-blue: #90b7d5;
}

body {
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    /*background-color: #faf9f9;*/
    color: var(--main-gray);
}

/* Background */
.bg-purple {
    background-color: var(--purple);
}
.bg-orange {
    background-color: var(--orange);
}


/* Text */
.text-orange {
    color: var(--orange);
}
.text-red {
    color: var(--red);
}
.text-green-secondary {
    color: var(--green-secondary)
}

.hr-orange {
    border-color: var(--orange);
    border-width: 3px;
}
.hr-purple {
    border-color: var(--purple);
    border-width: 3px;
}

.wrapper {
    margin: 35px 0 0 260px;
    width: calc(100vw - 260px);
}
.wrapper-container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}
.nav-holder h1 {
    font-size: 16px;
    letter-spacing: 3px;
    margin-top: 46px;
}
.email-verification-wrapper h1 {
    font-size: 25px;
}
.help-center a:hover,
a:hover,
.footer li.active a {
    color: var(--purple);
}
.not-found {
    min-height: calc(100vh - 70px);
}
.login-wrapper {
    min-height: 100vh;
}
.login-holder-box {
    max-width: 1000px;
    width: 100%;
}
.form-holder {
    padding: 50px 0;
}
.loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

@media (max-width: 767px) {
    .wrapper {
        margin: 35px 0 0 0;
        width: 100%;
    }
    .wrapper h1 {
        font-size: 25px;
    }
}