.my-invoice-wrapper .custom-table tr:last-child td {
    border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.custom-table td {
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
    color: var(--main-gray);
}
.custom-table th,
.custom-table td {
    border: none;
    border-top: 2px solid rgba(0, 0, 0, 0.125) !important;
    padding-top: 7px;
    padding-bottom: 7px;
}
.custom-table th {
    color: #9B9B9B;
    font-family: "Roboto";
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
}