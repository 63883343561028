.custom-input {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #F2F0F0;
    padding: 0;
    outline: none;
    box-shadow: none !important;
}
.custom-textarea {
    background-color: #F2F0F0;
    border: 2px solid #F2F0F0;
    border-radius: 0;
    box-shadow: none !important;
}
.custom-input:focus,
.has-success .custom-input {
    border-bottom: 2px solid var(--green-secondary);
}
.custom-textarea:focus,
.has-success .custom-textarea {
    border: 2px solid var(--green-secondary);
}
.has-error .custom-input {
    border-bottom: 2px solid var(--red);
}
.float-labels label {
    text-transform: uppercase;
}
.float-labels {
    position: relative;
    margin-bottom: 32px;
}
.float-labels label {
    transition: all 0.1s linear;
    position: absolute;
    left: 0;
    top: -9px;
    opacity: 0;
    color: #797979;
    font-size: 12px;
    line-height: 18px;
}
.float-labels {
    position: relative;
}
.float-labels label.show {
    top: -12px;
    margin: 0;
    opacity: 0.8;
}
.textarea-float-labels label.show {
    top: -23px;
}
.float-labels .switch {
    opacity: 1;
    position: relative;
    top: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.btn-back {
    background-color: transparent !important;
    color: var(--purple) !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}
.btn-submit {
    background-color: var(--green-secondary);
    border-color: var(--green-secondary);
    position: relative;
}
.btn-submit:hover {
    background-color: var(--green-secondary);
    border-color: var(--green-secondary);
}
.btn-reject {
    background-color: var(--red) !important;
    border-color: var(--red) !important;
}
.btn-reject:hover {
    opacity: 0.7;
}
.form-check {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.form-check-label::before {
    position: absolute;
    top: .1rem;
    left: -1.5rem;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 2px solid #F2F0F0;
}
.form-check-input:checked ~ .form-check-label::before {
    color: #fff;
    border-color: var(--orange);
    background-color: var(--orange);
}
.form-check .custom-control-label::before {
    border-radius: .25rem;
}
.form-check-label::before {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-check-label::after {
    position: absolute;
    top: .1rem;
    left: -1.5rem;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    content: "";
    background: no-repeat center / 72% 72%;
}
.form-check .form-check-input:checked ~ .form-check-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.form-check-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
}
.spinner {
    animation: spin infinite 1.5s linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
legend {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
}
.invoice-desc {
    margin-top: 43px;
}
.invoice-desc textarea {
    min-height: 100px;
}